import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';
import innovatorGif from '../../images/innovator.gif';

const StyledProjectsGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};

  a {
    position: relative;
    z-index: 1;
  }
`;

const StyledProject = styled.li`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;

  @media (max-width: 768px) {
    ${({ theme }) => theme.mixins.boxShadow};
  }

  &:not(:last-of-type) {
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &:nth-of-type(odd) {
    .project-content {
      grid-column: 7 / -1;
      text-align: right;

      @media (max-width: 1080px) {
        grid-column: 5 / -1;
      }
      @media (max-width: 768px) {
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        text-align: left;
      }
      @media (max-width: 480px) {
        padding: 25px 25px 20px;
      }
    }
    .project-tech-list {
      justify-content: flex-end;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      li {
        margin: 0 0 5px 20px;

        @media (max-width: 768px) {
          margin: 0 10px 5px 0;
        }
      }
    }
    .project-links {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: -10px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: 0;
      }
    }
    .project-image {
      grid-column: 1 / 8;

      @media (max-width: 768px) {
        grid-column: 1 / -1;
      }
    }
  }

  .project-content {
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;

    @media (max-width: 1080px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      grid-column: 1 / -1;
      padding: 40px 40px 30px;
      z-index: 5;
    }

    @media (max-width: 480px) {
      padding: 30px 25px 20px;
    }
  }

  .project-overline {
    margin: 10px 0;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .project-title {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 28px);

    @media (min-width: 768px) {
      margin: 0 0 20px;
    }

    @media (max-width: 768px) {
      color: var(--white);

      a {
        position: static;

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .project-description {
    ${({ theme }) => theme.mixins.boxShadow};
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    color: var(--light-slate);
    font-size: var(--fz-lg);

    @media (max-width: 768px) {
      padding: 20px 0;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }

    strong {
      color: var(--white);
      font-weight: normal;
    }
  }

  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 10px;
    padding: 0;
    list-style: none;

    li {
      margin: 0 20px 5px 0;
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 10px 5px 0;
        color: var(--lightest-slate);
      }
    }
  }

  .project-links {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    color: var(--lightest-slate);

    a {
      ${({ theme }) => theme.mixins.flexCenter};
      padding: 10px;

      &.external {
        svg {
          width: 22px;
          height: 22px;
          margin-top: -4px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .cta {
      ${({ theme }) => theme.mixins.smallButton};
      margin: 10px;
    }
  }

  .project-image {
    ${({ theme }) => theme.mixins.boxShadow};
    grid-column: 6 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      height: 100%;
      opacity: 0.25;
    }

    a {
      width: 100%;
      height: 100%;
      background-color: var(--green);
      border-radius: var(--border-radius);
      vertical-align: middle;

      &:hover,
      &:focus {
        background: transparent;
        outline: 0;

        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
      }
    }

    .img {
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1) brightness(90%);

      @media (max-width: 768px) {
        object-fit: cover;
        width: auto;
        height: 100%;
        filter: grayscale(100%) contrast(1) brightness(50%);
      }
    }
  }
`;

const Featured = () => {
  const data = useStaticQuery(graphql`
    {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/featured/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                childImageSharp {
                  gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              tech
              github
              external
              cta
              ios
              android
              youtube
            }
            html
          }
        }
      }
    }
  `);

  const featuredProjects = data.featured.edges.filter(({ node }) => node);
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const StylesTable = styled.table`
    width: 100%;
  `;
  const StylesTh = styled.th`
    padding: 10px;
    text-align: left;
    border: 1px solid;
  `;
  const StylesTd = styled.td`
    padding: 10px;
    text-align: left;
    border: 1px solid;
  `;
  return (
    <>
      <section id="projects">
        <h2 className="numbered-heading" ref={revealTitle}>
          Main Takeaways from John Cleese on Creativity:
        </h2>
        <br />
        <StyledProjectsGrid>
          <ul>
            <li>
              <p>
                <b>Creating a Space/Time Oasis:</b> To foster creativity, you need a dedicated,
                distraction-free space and time where you can relax and let your mind wander.
              </p>
            </li>
            <li>
              <p>
                <b>Balancing Open and Closed Modes:</b> Creativity thrives when you switch between
                an open, playful mode for idea generation and a focused, closed mode for
                implementation.
              </p>
            </li>
            <li>
              <p>
                <b>Embrace Risk and Humor:</b> Creativity involves taking risks and using humor to
                shift from serious, rigid thinking to a more open, flexible mindset.
              </p>
            </li>
          </ul>
          <br />
          <h3>Ideal Personal Space/Time Oasis at Home:</h3>
          <p>
            My ideal space/time oasis would be a quiet, comfortable room with natural light, minimal
            distractions, and a set time each day dedicated to creative work. This space would be
            free from interruptions, allowing me to fully engage in creative thinking.
          </p>
          <br />
          <h3>Potential Pitfalls:</h3>
          <p>
            The main pitfalls could include interruptions from household members, digital
            distractions like phones or emails, and the temptation to multitask. Setting clear
            boundaries and communicating the importance of this time to others is crucial.
          </p>
          <br />
          <h3>Principles for Constructing a Space/Time Oasis in the Workplace:</h3>
          <ul>
            <li>
              <b>Dedicated Space:</b> Create a quiet area or room where employees can retreat for
              focused work.
            </li>
            <li>
              <b>Scheduled Time:</b> Allow for blocks of uninterrupted time in the workday for
              creative tasks.
            </li>
            <li>
              <b>Encourage Playfulness:</b> Incorporate elements that promote relaxation and humor,
              such as comfortable seating and access to light-hearted activities.
            </li>
          </ul>
          <br />
          <h3>What Works for Me:</h3>
          <p>
            At home, I find that setting specific times for creative work, such as early mornings
            when it’s quiet, helps me stay focused. I also make sure my workspace is clean and free
            of distractions, and I use a combination of background music and natural light to create
            a relaxed atmosphere. In the workplace, having a clear schedule and a dedicated quiet
            space where I can retreat without interruptions has been key to maintaining productivity
            and creativity.
          </p>
        </StyledProjectsGrid>
      </section>
      <section id="dot-connection">
        <h2 className="numbered-heading">Connecting the Dots:</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div style={{ width: '100%', flex: 1 }}>
            <p>
              According to Steve Jobs, connecting the dots means understanding how different
              experiences, choices, and events in your life come together to shape your future,
              often in ways that aren’t immediately clear. By reflecting on these connections, you
              can gain insights into your strengths and goals, helping you make informed decisions.
            </p>
            <p>
              Recently, I connected the dots between my background in mobile and backend development
              and my studies in Business Information Systems (BIS). This combination helped me
              secure a job in Australia, where I apply both technical and business analyst skills in
              my role.
            </p>
            <br />
            <h3>Underlying Factors:</h3>
            <ul>
              <li>
                <b>Diverse Experience:</b> My varied background gave me a strong foundation.
              </li>
              <li>
                <b>Learning:</b> Studying BIS enhanced my business analysis abilities.
              </li>
              <li>
                <b>Adaptability:</b> Being open to new roles and environments helped me apply my
                skills effectively.
              </li>
            </ul>
          </div>
          <div style={{ width: '100%', marginLeft: 20, flex: 1 }}>
            <div className="wrapper">
              <StaticImage
                style={{ borderRadius: '8' }}
                className="img"
                src="../../images/dot.png"
                width={500}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="creativity-and-risk">
        <h2 className="numbered-heading">Creativity and Risk</h2>
        <p>
          <b>Creativity and risk-taking</b> are deeply connected. Being creative often means trying
          new things and stepping outside your comfort zone, which involves taking risks. These
          risks might lead to failure, but they are also where the most significant and innovative
          ideas come from. Without taking risks, creativity can become stagnant, limiting your
          potential to grow and discover new possibilities. So, embracing risk is essential to
          unlocking true creative potential.
        </p>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div style={{ width: '100%', flex: 1 }}>
            <div className="wrapper">
              <StaticImage
                style={{ borderRadius: '8' }}
                className="img"
                src="../../images/risk.jpg"
                width={500}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>
          </div>
          <div style={{ width: '100%', marginLeft: 20, flex: 1 }}>
            <p
              style={{
                textAlign: 'justify',
              }}>
              I believe that creativity and risk-taking are indeed closely connected. To create
              something truly original, you often need to step outside of your comfort zone and take
              risks, whether it's experimenting with new ideas, challenging the status quo, or
              venturing into uncharted territories. These risks can lead to failure, but they are
              also where the most significant breakthroughs happen. Without the willingness to take
              risks, creativity can stagnate, limiting innovation and personal growth. Risk-taking
              is essential for pushing boundaries and discovering new possibilities.
            </p>
          </div>
        </div>
      </section>

      <section id="thinking-styles">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div style={{ width: '100%', flex: 1 }}>
            <h2 className="numbered-heading">Thinking Styles</h2>
            <p>
              After completing the tests, I found that my thinking styles are primarily{' '}
              <b>Innovator </b> and<b> Doer (Concrete Thinking)</b>. <b>As an Innovator</b>, I’m
              constantly generating new ideas and seeking progress, which fuels my creativity.
              However, this can sometimes lead to challenges in execution. <b>My Doer side</b>,
              which focuses on practical and actionable steps, helps me turn ideas into reality but
              can limit creativity if I become too focused on practicality.
            </p>
            <p>
              <b>Example: </b>In daily life, being an Innovator allows me to think creatively and
              push boundaries, but I sometimes struggle with following through. My Doer mindset
              helps me execute tasks efficiently, but it might hold back my creativity when I’m too
              focused on getting things done quickly.
            </p>
            <p>
              <b>Plans for Development:</b> Balancing innovation with concrete execution by
              mastering follow-through and collaborating with others who excel in different areas.
            </p>
            <p>
              <b>Creativity Type:</b> Knowing you’re an Innovator helps you harness your creativity
              in leading change, while understanding your Doer side ensures you can implement your
              ideas effectively.
            </p>
          </div>

          <div style={{ width: '100%', marginLeft: 20, flex: 1 }}>
            <img src={innovatorGif} />
          </div>
        </div>
      </section>
      <section id="creativity-and-innovation">
        <div className="inner">
          <div>
            <h2 className="numbered-heading">Creativity and Innovation: Self-Reflection</h2>
            <h3>Self-Reflection Worksheet:</h3>
            <StylesTable>
              <thead>
                <tr>
                  <StylesTh>Statement</StylesTh>
                  <StylesTh>Rating</StylesTh>
                  <StylesTh>Comments</StylesTh>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <StylesTd>1. I am a creative person</StylesTd>
                  <StylesTd>
                    <b>7</b>
                  </StylesTd>
                  <StylesTd>Sometimes I feel creative, sometimes I don’t.</StylesTd>
                </tr>
                <tr>
                  <StylesTd>2. I have access to a ‘space-time oasis’</StylesTd>
                  <StylesTd>5</StylesTd>
                  <StylesTd>I might not even know about it.</StylesTd>
                </tr>
                <tr>
                  <StylesTd>3. I regularly spend time in my ‘space-time oasis’</StylesTd>
                  <StylesTd>3</StylesTd>
                  <StylesTd>Not quite sure.</StylesTd>
                </tr>
                <tr>
                  <StylesTd>4. Most people would think of me as being open-minded</StylesTd>
                  <StylesTd>10</StylesTd>
                  <StylesTd>I'm very open-minded.</StylesTd>
                </tr>
                <tr>
                  <StylesTd>5. I know how to ‘play’</StylesTd>
                  <StylesTd>8</StylesTd>
                  <StylesTd>
                    I enjoy taking breaks and engaging in playful activities, but sometimes I
                    struggle to make time for them.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>6. I am a humorous person</StylesTd>
                  <StylesTd>7</StylesTd>
                  <StylesTd>
                    I can be humorous in the right situations, though I’m sometimes more reserved.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>7. I often operate in Open Mode</StylesTd>
                  <StylesTd>10</StylesTd>
                  <StylesTd>
                    I am very open to new ideas and perspectives, especially when brainstorming or
                    problem-solving.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>8. I am experienced in the 4-step design thinking process</StylesTd>
                  <StylesTd>7</StylesTd>
                  <StylesTd>
                    I’m familiar with the process, but I believe there’s always room for improvement
                    and deeper understanding.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>9. I know my dominant thinking style</StylesTd>
                  <StylesTd>8</StylesTd>
                  <StylesTd>
                    I've identified my thinking style, though I try to stay flexible and adapt
                    depending on the situation.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>
                    10. I have no problem changing my perspectives when called for
                  </StylesTd>
                  <StylesTd>8</StylesTd>
                  <StylesTd>
                    I rated this 8 because sometimes I need time to process the changes.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>11. I have a good imagination</StylesTd>
                  <StylesTd>8</StylesTd>
                  <StylesTd>
                    My imagination is strong, especially when I’m in a creative or relaxed
                    environment.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>12. I am good at critical thinking</StylesTd>
                  <StylesTd>8</StylesTd>
                  <StylesTd>
                    I’m confident in my critical thinking skills, but I continuously work on
                    sharpening them.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>13. I am highly motivated at what I do</StylesTd>
                  <StylesTd>10</StylesTd>
                  <StylesTd>I always feel energized to do so.</StylesTd>
                </tr>
                <tr>
                  <StylesTd>14. I have a passion for what I do</StylesTd>
                  <StylesTd>10</StylesTd>
                  <StylesTd>N/A</StylesTd>
                </tr>
                <tr>
                  <StylesTd>15. I often use tools to help me solve problems</StylesTd>
                  <StylesTd>8</StylesTd>
                  <StylesTd>
                    I regularly rely on tools, though I sometimes prefer brainstorming solutions
                    without them first.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>16. I have a good set of creativity tools in my toolbox</StylesTd>
                  <StylesTd>6</StylesTd>
                  <StylesTd>
                    I rated this 6 because I'm not sure if they are called creativity tools.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>17. I don’t have major creativity blocks/barriers</StylesTd>
                  <StylesTd>8</StylesTd>
                  <StylesTd>
                    While I do experience blocks occasionally, I usually find ways to overcome them
                    quickly.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>18. I am good at lateral thinking</StylesTd>
                  <StylesTd>6</StylesTd>
                  <StylesTd>
                    I believe I have potential for lateral thinking, but I recognize that I need
                    more practice.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>19. I have ‘creativity playmates’ whom I can trust</StylesTd>
                  <StylesTd>1</StylesTd>
                  <StylesTd>
                    Currently, I don’t have many people I collaborate with creatively, but I’m open
                    to finding such connections.
                  </StylesTd>
                </tr>
                <tr>
                  <StylesTd>20. I have many good ideas, but I never implement them</StylesTd>
                  <StylesTd>6</StylesTd>
                  <StylesTd>Sometimes I do implement them.</StylesTd>
                </tr>
              </tbody>
            </StylesTable>
            <br />
            <h3>Reflection and Identify top 5 issues</h3>
            <p>
              In reflecting on my self-assessment, I have identified five key areas for improvement.{' '}
            </p>
            <ul>
              <li>
                <b>Use 'Space-Time Oasis' More:</b> I need to define and regularly use a 'space-time
                oasis' for creativity and relaxation.
              </li>
              <li>
                <b>Improve Lateral Thinking:</b> I want to practice thinking outside the box more
                often.
              </li>
              <li>
                <b>Find Creativity Partners:</b> I want to connect with people who can help inspire
                and challenge my creativity.
              </li>
              <li>
                <b>Act on Good Ideas:</b> I need to focus on turning my good ideas into action.
              </li>
              <li>
                <b>Get More Creativity Tools:</b> I want to explore and add more tools to help with
                problem-solving and innovation.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="new-insights">
        <h2 className="numbered-heading">New Insights and Reflection</h2>
        <p>
          Somehow, through this exercise, I realized that I’ve been taking risks without even
          noticing it, and those risks have led to creative breakthroughs. I hadn’t fully understood
          before how much stepping out of my comfort zone contributed to my creative process. This
          reflection made me see that by embracing uncertainty and being willing to try new things,
          I’ve been able to foster creativity in ways I hadn’t consciously recognized.
        </p>
      </section>
    </>
  );
};

export default Featured;
