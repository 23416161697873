import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import { KEY_CODES } from '@utils';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledJobsSection = styled.section`
  max-width: 700px;

  .inner {
    display: flex;

    @media (max-width: 600px) {
      display: block;
    }

    // Prevent container from jumping
    @media (min-width: 700px) {
      min-height: 340px;
    }
  }
`;

const StyledTabList = styled.div`
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 600px) {
    display: flex;
    overflow-x: auto;
    width: calc(100% + 100px);
    padding-left: 50px;
    margin-left: -50px;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    width: calc(100% + 50px);
    padding-left: 25px;
    margin-left: -25px;
  }

  li {
    &:first-of-type {
      @media (max-width: 600px) {
        margin-left: 50px;
      }
      @media (max-width: 480px) {
        margin-left: 25px;
      }
    }
    &:last-of-type {
      @media (max-width: 600px) {
        padding-right: 50px;
      }
      @media (max-width: 480px) {
        padding-right: 25px;
      }
    }
  }
`;

const StyledTabButton = styled.button`
  ${({ theme }) => theme.mixins.link};
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--tab-height);
  padding: 0 20px 2px;
  border-left: 2px solid var(--lightest-navy);
  background-color: transparent;
  color: ${({ isActive }) => (isActive ? 'var(--green)' : 'var(--slate)')};
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 0 15px 2px;
  }
  @media (max-width: 600px) {
    ${({ theme }) => theme.mixins.flexCenter};
    min-width: 120px;
    padding: 0 15px;
    border-left: 0;
    border-bottom: 2px solid var(--lightest-navy);
    text-align: center;
  }

  &:hover,
  &:focus {
    background-color: var(--light-navy);
  }
`;

const StyledHighlight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  border-radius: var(--border-radius);
  background: var(--green);
  transform: translateY(calc(${({ activeTabId }) => activeTabId} * var(--tab-height)));
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.1s;

  @media (max-width: 600px) {
    top: auto;
    bottom: 0;
    width: 100%;
    max-width: var(--tab-width);
    height: 2px;
    margin-left: 50px;
    transform: translateX(calc(${({ activeTabId }) => activeTabId} * var(--tab-width)));
  }
  @media (max-width: 480px) {
    margin-left: 25px;
  }
`;

const StyledTabPanels = styled.div`
  position: relative;
  width: 100%;
  margin-left: 20px;

  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const StyledTabPanel = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 5px;

  ul {
    ${({ theme }) => theme.mixins.fancyList};
  }

  h3 {
    margin-bottom: 2px;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.3;

    .company {
      color: var(--green);
    }
  }

  .range {
    margin-bottom: 25px;
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
  }
`;

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/jobs/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;

  const [activeTabId, setActiveTabId] = useState(0);
  const [tabFocus, setTabFocus] = useState(null);
  const tabs = useRef([]);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const focusTab = () => {
    if (tabs.current[tabFocus]) {
      tabs.current[tabFocus].focus();
      return;
    }
    // If we're at the end, go to the start
    if (tabFocus >= tabs.current.length) {
      setTabFocus(0);
    }
    // If we're at the start, move to the end
    if (tabFocus < 0) {
      setTabFocus(tabs.current.length - 1);
    }
  };

  // Only re-run the effect if tabFocus changes
  useEffect(() => focusTab(), [tabFocus]);

  // Focus on tabs when using up & down arrow keys
  const onKeyDown = e => {
    switch (e.key) {
      case KEY_CODES.ARROW_UP: {
        e.preventDefault();
        setTabFocus(tabFocus - 1);
        break;
      }

      case KEY_CODES.ARROW_DOWN: {
        e.preventDefault();
        setTabFocus(tabFocus + 1);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <>
      <StyledJobsSection id="poem" ref={revealContainer}>
        <h2 className="numbered-heading">Creativity Poem</h2>

        <h3>
          <span>The Unseen Road</span>
        </h3>
        <p>
          I walk the road that none have seen, A path that’s wild, a place between. <br />
          To challenge myself, to step away, Is where creativity comes to play. <br />
          The comfort zone, it calls me back, But in the risk, there’s no lack. <br />
          For in the challenge, I find my voice, To create, to explore, becomes my choice.
        </p>
      </StyledJobsSection>

      <StyledJobsSection id="creativity" ref={revealContainer}>
        <h2 className="numbered-heading">Creativity comes from</h2>
        <h3>Creativity components</h3>
        <ul>
          <li>
            <b>Domain-Relevant Skills:</b> Domain-Relevant Skills: My technical expertise and
            understanding of the market were crucial in developing this feature, aligning with
            Amabile's emphasis on domain knowledge​.
          </li>
          <li>
            <b>Creativity-Relevant Processes:</b> I brainstormed, experimented with prototypes, and
            iterated on ideas, reflecting Amabile’s focus on taking risks and thinking outside the
            box.
          </li>
          <li>
            <b>Task Motivation:</b> I was intrinsically motivated, excited by the challenge and the
            potential impact of the feature, which drove my creativity.
          </li>
        </ul>
        <h3>Outcome</h3>
        <p>
          The feature was successfully developed and became a significant differentiator for our
          product in the market. This experience illustrates how the combination of domain
          expertise, creative thinking processes, and intrinsic motivation can lead to successful
          creative outcome
        </p>
        <h3>Example of a Missed Opportunity</h3>
        <p>
          
          While working on a routine consultancy project, I chose a quick, familiar solution rather
          than exploring creative alternatives.
        </p>
        <br />
        <b>What Was Missing:</b>
        <ul>
          <li>
            <b>Creativity-Relevant Processes: </b> I didn’t engage in brainstorming or consider
            different approaches, limiting creative potential.
          </li>
          <li>
            <b>Task Motivation:</b> My focus was on meeting the deadline, with little intrinsic
            interest, which hindered creativity.
          </li>
          <li>
            <b>Outcome:</b> The solution was conventional and unremarkable, highlighting how the
            absence of creative processes and motivation can lead to missed opportunities.
          </li>
        </ul>
      </StyledJobsSection>

      <StyledJobsSection id="jobs" ref={revealContainer}>
        <h2 className="numbered-heading">Top 3 Insights from Sir Ken Robinson's Talk</h2>
        <ul>
          <li>
            <p>
              <b>Creativity Is Crucial:</b> Creativity is just as important as literacy and should
              be a fundamental part of education.
            </p>
          </li>
          <li>
            <p>
              <b>Education Can Suppress Creativity:</b> The current education system often
              discourages creative thinking by focusing too much on standardized testing and
              conformity.
            </p>
          </li>
          <li>
            <p>
              <b>Recognising Multiple Intelligences:</b> Schools should value different types of
              intelligence, including artistic and creative abilities, not just academic ones.
            </p>
          </li>
        </ul>
        <p>
          Yes, I agree with his message. Creativity isn’t something you can teach directly; it’s
          something that needs to be nurtured through encouragement, opportunities, and a supportive
          environment. Robinson’s idea that creativity is a process, not a single event, makes sense
          because it involves exploring, experimenting, and refining ideas. This approach helps
          people develop their own unique ways of thinking and solving problems, which is more
          effective than just following instructions.
        </p>
      </StyledJobsSection>
    </>
  );
};

export default Jobs;
