import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: left;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
  li {
    margin: 10px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="reference" ref={revealContainer}>
      <h2 className="numbered-heading overline">Reference</h2>
      <h2 className="title">References</h2>
      <ol>
        <li>
          Amabile, T. M. (2012, May 22). Componential theory of creativity. HBS Working Knowledge.
          <a href="https://hbswk.hbs.edu/item/componential-theory-of-creativity">
            https://hbswk.hbs.edu/item/componential-theory-of-creativity
          </a>
        </li>
        <li>
          Popova, M. (2012, April 12). John Cleese on the Five Factors to Make Your Life More
          Creative. The Marginalian.
          <a href="https://www.themarginalian.org/2012/04/12/john-cleese-on-creativity-1991/">
            https://www.themarginalian.org/2012/04/12/john-cleese-on-creativity-1991/
          </a>
        </li>
        <li>
          Chu, C. (2017, February 28). The perfect conditions for creativity, according to Monty
          Python’s John Cleese. Quartz.
          <a href="https://qz.com/919351/the-perfect-conditions-for-creativity-according-to-monty-pythons-john-cleese">
            https://qz.com/919351/the-perfect-conditions-for-creativity-according-to-monty-pythons-john-cleese
          </a>
        </li>
        <li>
          Stanford Report. (2005, June 12). “You’ve got to find what you love,” Jobs says | Stanford
          Report. News.stanford.edu.
          <a href="https://news.stanford.edu/stories/2005/06/youve-got-find-love-jobs-says">
            https://news.stanford.edu/stories/2005/06/youve-got-find-love-jobs-says
          </a>
        </li>
        <li>
          Ellsberg, M. (2013, May 17). The Necessity of Creative Risk Taking. Big Think.
          <a href="https://bigthink.com/articles/the-necessity-of-creative-risk-taking/">
            https://bigthink.com/articles/the-necessity-of-creative-risk-taking/
          </a>
        </li>
        <li>
          Popova, M. (2012, April 12). John Cleese on the five factors to make your life more
          creative. The Marginalian.
          <a href="https://www.themarginalian.org/2012/04/12/john-cleese-on-creativity-1991">
            https://www.themarginalian.org/2012/04/12/john-cleese-on-creativity-1991
          </a>
        </li>
      </ol>
    </StyledContactSection>
  );
};

export default Contact;
